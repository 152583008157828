import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './quasar'

import Storage from 'vue-ls';
Vue.use(Storage)


Vue.use(require('vue-moment'));
import Vue2Filters from 'vue2-filters'

var Vue2FiltersConfig = {
  currency: {
    symbol: 'Rp',
    decimalDigits: 0,
    thousandsSeparator: '.', decimalSeparator: ',',
    spaceBetweenAmountAndSymbol: true,
  },
}

Vue.use(Vue2Filters, Vue2FiltersConfig)




Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
