<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="row no-wrap items-center">
        <q-avatar icon="warning" color="negative" text-color="white" />
        <a class="q-ml-md">{{ text }}</a>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-actions class="row justify-between">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn flat color="primary" label="ok" @click="onOKClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["text"],
  mounted() {},
  methods: {
    show() {
      this.$refs.dialog.show();
    },
    hide() {
      this.$refs.dialog.hide();
    },
    onDialogHide() {
      this.$emit("hide");
    },
    onOKClick() {
      this.$emit("ok");
      this.hide();
    },
    onCancelClick() {
      this.hide();
    },
  },
};
</script>
