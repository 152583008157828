import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sortBy: 'date',
    isDeleting: false,
    items: [],
    summary: {
      total: 0,
      suka: 0,
      butuh: 0
    }
  },
  mutations: {
    SetSortBy (state, val) {
      state.sortBy = val
    },
    SetIsDeleting (state, val) {
      state.isDeleting = val
    },
    SetItems(state, val) {
      state.items = val
    },
    PushItems(state, val) {
      state.items.push(val)
    },
    SpliceItems(state, idx) {
      state.items.splice(idx, 1)
    },
    EditItems(state, obj) {
      let idx = obj.idx
      let data = obj.data
      state.items[idx].name = data.name;
      state.items[idx].price = data.price;
      state.items[idx].status = data.status;
      state.items[idx].date = data.date;
    },
    RestoreItems(state,obj) {
      state.items = JSON.parse(JSON.stringify(obj.items)) 
      state.summary = JSON.parse(JSON.stringify(obj.summary))
      Vue.ls.set("items", state.items);
      Vue.ls.set("summary", state.summary);
    },
    SetSummary(state, val) {
      state.summary = val
    },

  },
  actions: {
  },
  modules: {
  }
})
