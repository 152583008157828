import Vue from 'vue'

import './styles/quasar.scss'
import '@quasar/extras/roboto-font/roboto-font.css'
import '@quasar/extras/material-icons/material-icons.css'
import '@quasar/extras/fontawesome-v5/fontawesome-v5.css'
import Quasar from 'quasar/src/vue-plugin.js';import Screen from 'quasar/src/plugins/Screen.js';import Dialog from 'quasar/src/plugins/Dialog.js';

Vue.use(Quasar, {
  config: {},
  plugins: {
    Dialog
  }
 })