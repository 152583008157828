<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin ">
      <q-card-section class="text-caption text-grey">
        Klik data yang akan di restore!
      </q-card-section>
      <q-scroll-area style="height: 400px" class="bg-grey-3">
        <q-list separator>
          <q-item clickable v-for="(el, idx) in backups" :key="idx">
            <q-item-section @click="selectBackup(el)">
              <q-item-label>{{ el.name }}</q-item-label>
              <q-item-label caption>{{
                el.date | moment("DD MMM YYYY")
              }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-scroll-area>
      <q-separator></q-separator>
      <q-card-actions align="left">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import ConfirmAction from "@/components/ConfirmAction";
export default {
  data() {
    return {
      backups: [],
    };
  },
  mounted() {
    this.backups = this.$ls.get("backup");
  },
  methods: {
    selectBackup(backup) {
      this.$q
        .dialog({
          component: ConfirmAction,
          parent: this,
          text: "Data lama akan dihapus pada proses restore! Anda yakin?",
        })
        .onOk(() => {
          this.$store.commit("RestoreItems", backup);
          this.onOKClick();
        })
        .onCancel(() => {});
    },
    show() {
      this.$refs.dialog.show();
    },
    hide() {
      this.$refs.dialog.hide();
    },
    onDialogHide() {
      this.$emit("hide");
    },
    onOKClick() {
      this.$emit("ok");
      this.hide();
    },
    onCancelClick() {
      this.hide();
    },
  },
};
</script>
