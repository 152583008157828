<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section class="column q-gutter-sm">
        <q-input filled label="Item Name" v-model="item.name"></q-input>
        <q-input filled label="Item Price" v-model="item.price"></q-input>
        <q-input filled label="Transaction Date" v-model="item.date"></q-input>
        <div>
          <q-radio v-model="item.status" label="Butuh" val="butuh"></q-radio>
          <q-radio v-model="item.status" label="Suka" val="suka"></q-radio>
        </div>
      </q-card-section>
      <q-separator></q-separator>
      <q-card-actions class="row justify-between">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn unelevated color="primary" label="save" @click="onOKClick" />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  props: ["pItem"],
  data() {
    return {
      item: {
        name: "",
        price: "",
        status: "",
        date: "",
        id: "",
        created: "",
      },
    };
  },
  mounted() {
    this.item = JSON.parse(JSON.stringify(this.pItem));
  },
  methods: {
    show() {
      this.$refs.dialog.show();
    },
    hide() {
      this.$refs.dialog.hide();
    },
    onDialogHide() {
      this.$emit("hide");
    },
    onOKClick() {
      this.$emit("ok", this.item);
      this.hide();
    },
    onCancelClick() {
      this.hide();
    },
  },
};
</script>
