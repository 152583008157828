<template>
  <div style="width: 425px" class="bg-white">
    <template v-if="$store.state.isDeleting">
      <div style="height: 224px" class="column justify-center bg-grey-3">
        <div class="row bg-white">
          <q-btn
            flat
            class="col-6"
            label="select all"
            @click="toggleSelection(true)"
          ></q-btn>
          <q-btn
            flat
            class="col-6"
            label="unselect all"
            @click="toggleSelection(false)"
          ></q-btn>
        </div>

        <div class="row bg-white q-mt-sm">
          <q-btn
            class="col-6"
            color="secondary"
            flat
            label="back"
            @click="$store.commit('SetIsDeleting', false)"
          ></q-btn>
          <q-btn
            class="col-6"
            color="negative"
            icon="delete"
            flat
            label="delete"
            @click="massDelete"
          ></q-btn>
        </div>
      </div>
    </template>
    <template v-else>
      <q-input filled type="textarea" v-model="tarea"></q-input>
      <div class="row justify-evenly">
        <q-btn
          flat
          icon="sentiment_neutral"
          label="butuh"
          color="primary"
          @click="addItem('butuh')"
          style="width: 50%"
        ></q-btn>
        <q-btn
          flat
          icon="sentiment_very_satisfied"
          label="suka"
          color="pink"
          @click="addItem('suka')"
          style="width: 50%"
        ></q-btn>
      </div>
      <q-separator></q-separator>
      <q-tabs v-model="tab" class="text-primary bg-blue-1">
        <q-tab name="all" label="all">
          <div>{{ summary.total | currency }}</div>
        </q-tab>
        <q-tab name="butuh" label="butuh">
          <div>{{ summary.butuh | currency }}</div>
        </q-tab>
        <q-tab name="suka" label="suka">
          <div>{{ summary.suka | currency }}</div>
        </q-tab>
      </q-tabs>
    </template>
    <q-separator></q-separator>

    <q-scroll-area style="height: calc(100vh - 280px)">
      <q-list>
        <q-item
          v-for="val in filteredItems"
          :key="val.id"
          :class="{ 'bg-pink-1': val.status == 'suka' }"
        >
          <q-item-section avatar v-show="$store.state.isDeleting">
            <q-checkbox v-model="val.isCheck"></q-checkbox>
          </q-item-section>
          <q-item-section>
            <q-item-label>{{ val.name }}</q-item-label>
            <q-item-label caption>{{
              val.date | moment("DD MMM YYYY")
            }}</q-item-label>
          </q-item-section>
          <q-item-section top class="text-right">
            <q-item-label>{{ val.price | currency }}</q-item-label>
          </q-item-section>
          <q-item-section top side>
            <q-btn
              flat
              dense
              icon="more_vert"
              v-show="!$store.state.isDeleting"
            >
              <q-menu transition-show="jump-down" transition-hide="jump-up">
                <q-list>
                  <q-item clickable v-close-popup @click="editItem(val)"
                    >edit</q-item
                  >
                </q-list>
              </q-menu>
            </q-btn>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import FormEditItem from "@/components/FormEditItem";
import ConfirmAction from "@/components/ConfirmAction";
import moment from "moment";

export default {
  data() {
    return {
      tab: "all",
      tarea: "",
    };
  },
  computed: {
    items: {
      get() {
        return this.$store.state.items;
      },
      set(val) {
        this.$store.commit("SetItems", val);
      },
    },
    summary: {
      get() {
        return this.$store.state.summary;
      },
      set(val) {
        this.$store.commit("SetSummary", val);
      },
    },
    filteredItems() {
      let temp = this.items.slice(0);
      let ret = temp;
      let sortBy = this.$store.state.sortBy;
      if (this.tab == "all") {
      } else {
        ret = temp.filter((val) => {
          return val.status == this.tab;
        });
      }
      if (sortBy == "price") {
        return ret.sort((a, b) => {
          return b.price - a.price;
        });
      } else {
        return ret.sort((a, b) => {
          let dateA = new Date(a.date);
          let dateB = new Date(b.date);
          let dateC = new Date(a.created);
          let dateD = new Date(b.created);

          if (dateB - dateA) {
            return dateB - dateA;
          } else {
            return dateD - dateC;
          }
        });
      }
    },
  },
  created() {
    if (this.$ls.get("items", -1) != -1) {
      this.items = this.$ls.get("items");
      this.summary = this.$ls.get("summary");
    } else {
      this.$ls.set("items", this.items);
      this.$ls.set("summary", this.summary);
    }
  },
  methods: {
    toggleSelection(val) {
      this.items.forEach((el) => (el.isCheck = val));
    },
    massDelete() {
      this.$q
        .dialog({
          component: ConfirmAction,
          parent: this,
          text: "Anda yakin menghapus item-item ini?",
        })
        .onOk(() => {
          var len = this.items.length;
          for (var i = len - 1; i >= 0; i--) {
            if (this.items[i].isCheck) {
              this.$store.commit("SpliceItems", i);
            }
          }
          this.saveAll();
        })
        .onCancel(() => {});
    },

    saveAll() {
      this.updateSummary();
      this.$ls.set("items", this.items);
      this.$ls.set("summary", this.summary);
    },
    editItem(item) {
      this.$q
        .dialog({
          component: FormEditItem,
          parent: this,
          pItem: item,
        })
        .onOk((newData) => {
          let pos = this.items.findIndex((val) => {
            return val.id == item.id;
          });

          let temp = {
            idx: pos,
            data: newData,
          };
          this.$store.commit("EditItems", temp);
          // save data
          this.saveAll();
        });
    },
    addItem(stat) {
      let temp = this.tarea.split("\n");
      let len = temp.length;
      if (len < 2) return;

      for (let i = 0; i < len; i += 2) {
        if (i + 1 == len) break;
        // 1st row name, 2nd row price in thousand
        let item = {
          name: temp[i],
          price: temp[i + 1] * 1000,
          status: stat,
          date: moment().format("YYYY-MM-DD"),
          created: new Date(),
          id: uuidv4(),
          isCheck: false,
        };

        this.$store.commit("PushItems", item);
      }

      // save data
      this.saveAll();

      // clear textarea
      this.tarea = "";
    },
    updateSummary() {
      let total = 0;
      let suka = 0;
      let butuh = 0;
      this.items.forEach((val, idx) => {
        if (val.status == "suka") {
          suka += parseFloat(val.price);
        } else {
          butuh += parseFloat(val.price);
        }
        total += parseFloat(val.price);
      });

      this.summary = {
        total: total,
        suka: suka,
        butuh: butuh,
      };
    },
  },
};
</script>
<style lang="scss" scoped></style>
