<template>
  <q-layout view="hHh lpR fFf">
    <q-header bordered class="bg-primary text-white">
      <q-toolbar>
        <q-toolbar-title>Expense</q-toolbar-title>
        <q-btn flat dense icon="more_vert">
          <q-menu transition-show="jump-down" transition-hide="jump-up">
            <q-list style="width: 100vw;">
              <q-item
                @click="$store.commit('SetSortBy', 'date')"
                clickable
                v-close-popup
              >
                <q-item-section avatar>
                  <q-icon
                    v-show="$store.state.sortBy == 'date'"
                    name="check"
                    color="positive"
                  ></q-icon>
                </q-item-section>
                <q-item-section>Sort by date</q-item-section>
              </q-item>
              <q-item
                @click="$store.commit('SetSortBy', 'price')"
                clickable
                v-close-popup
              >
                <q-item-section avatar>
                  <q-icon
                    v-show="$store.state.sortBy == 'price'"
                    name="check"
                    color="positive"
                  ></q-icon>
                </q-item-section>
                <q-item-section>Sort by price</q-item-section>
              </q-item>
              <q-separator></q-separator>
              <q-item clickable v-close-popup @click="backup">
                <q-item-section avatar>
                  <q-icon name="backup" color="grey"></q-icon>
                </q-item-section>
                <q-item-section>Backup</q-item-section>
              </q-item>
              <q-item clickable v-close-popup @click="restore">
                <q-item-section avatar>
                  <q-icon name="restore" color="grey"></q-icon>
                </q-item-section>
                <q-item-section>Restore</q-item-section>
              </q-item>
              <q-separator></q-separator>
              <q-item
                @click="$store.commit('SetIsDeleting', true)"
                clickable
                v-close-popup
              >
                <q-item-section avatar>
                  <q-icon name="delete" color="grey"></q-icon>
                </q-item-section>
                <q-item-section>Delete data(s)</q-item-section>
              </q-item>
            </q-list>
          </q-menu>
        </q-btn>
      </q-toolbar>
    </q-header>

    <q-page-container class="bg-indigo-1 row justify-center">
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import FormBackup from "@/components/FormBackup";
import FormRestore from "@/components/FormRestore";
export default {
  mounted() {},
  data() {
    return {};
  },
  methods: {
    backup() {
      this.$q.dialog({
        component: FormBackup,
        parent: this,
      });
    },
    restore() {
      this.$q.dialog({
        component: FormRestore,
        parent: this,
      });
    },
  },
};
</script>
