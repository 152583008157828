<template>
  <q-dialog ref="dialog" @hide="onDialogHide">
    <q-card class="q-dialog-plugin">
      <q-card-section>
        <q-input filled label="Backup Name" v-model="backupName"></q-input>
      </q-card-section>
      <q-separator></q-separator>
      <q-scroll-area style="height: 400px" class="bg-grey-3">
        <q-list separator>
          <q-item v-for="(el, idx) in backups" :key="idx">
            <q-item-section>
              <q-item-label>{{ el.name }}</q-item-label>
              <q-item-label caption>{{
                el.date | moment("DD MMM YYYY")
              }}</q-item-label>
            </q-item-section>
            <q-item-section side>
              <div class="row q-gutter-lg">
                <q-btn flat dense icon="save" @click="saveBackup(idx)"></q-btn>
                <q-btn
                  flat
                  dense
                  icon="delete"
                  @click="deleteBackup(idx)"
                ></q-btn>
              </div>
            </q-item-section>
          </q-item>
        </q-list>
      </q-scroll-area>
      <q-separator></q-separator>
      <q-card-actions class="row justify-between">
        <q-btn flat color="primary" label="Cancel" @click="onCancelClick" />
        <q-btn
          unelevated
          color="primary"
          label="new backup"
          @click="onOKClick"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import ConfirmAction from "@/components/ConfirmAction";
export default {
  data() {
    return {
      backupName: "",
      backups: [],
    };
  },
  mounted() {
    this.backups = this.$ls.get("backup");
  },
  methods: {
    saveBackup(idx) {
      this.backups[idx].items = this.$ls.get("items");
      this.backups[idx].summary = this.$ls.get("summary");
      this.backups[idx].date = new Date();
      this.$ls.set("backup", this.backups);
      this.$emit("ok");
      this.hide();
    },
    deleteBackup(idx) {
      this.$q
        .dialog({
          component: ConfirmAction,
          parent: this,
          text: "Anda yakin menghapus backup ini?",
        })
        .onOk(() => {
          this.backups.splice(idx, 1);
          this.$ls.set("backup", this.backups);
        })
        .onCancel(() => {});
    },
    show() {
      this.$refs.dialog.show();
    },
    hide() {
      this.$refs.dialog.hide();
    },
    onDialogHide() {
      this.$emit("hide");
    },
    onOKClick() {
      let backup = {
        name: this.backupName,
        items: this.$ls.get("items"),
        summary: this.$ls.get("summary"),
        date: new Date(),
      };

      // check if any
      if (this.$ls.get("backup", -1) == -1) {
        let newBackup = [];
        newBackup.unshift(backup);
        this.$ls.set("backup", newBackup);
      } else {
        let oldBackup = this.$ls.get("backup");
        oldBackup.unshift(backup);
        this.$ls.set("backup", oldBackup);
      }

      this.$emit("ok");
      this.hide();
    },
    onCancelClick() {
      this.hide();
    },
  },
};
</script>
